import axios from '@/common/axios';
import store from '@/store';

export default {
    async getUserInfo() {
        return await axios.post('/user/info');
    },
    async updateUser(params) {
        return await axios.post("/user/update", params)
    },
    async updateEmergency(params) {
        return await axios.post("/user/updateEmergency", params)
    },
    async uploadIdentity(params) {
        return await axios.post("/user/identity/apply", params)
    },
    async getIdentity() {
        return await axios.post("/user/identity")
    },
    async updateBankcard(params) {
        return await axios.post("/user/bank/binding", params)
    },
    async uploadSignatureImg(params) {
        return await axios.post("/user/signatureImg/upload", params)
    },
    async unlock(params) {
        return await axios.post("/user/unlock", params)
    },
    //获取公司配置
    async getAllCompanyConfigs() {
        const data = await axios.post('/loan/getAllCompanyConfigs', {appId: process.env.DEPLOY_CONFIGS.appId});
        const stringList = ['CUSTOMER_SERVICE_FACEBOOK', 'ABOUT_US', 'FAQ', "WHATSAPP", "LINE", "WEBSITE"]
        for (let item in data) {
            if (stringList.includes(item)) {
                store.commit(item.toLowerCase(), data[item]);
            } else {
                try {
                    store.commit(item.toLowerCase(), JSON.parse(data[item]));
                } catch (e) {
                    store.commit(item.toLowerCase(), data[item]);
                }
            }
        }
    }
}