<template>
  <van-popup
      v-model="showSign"
      position="bottom"
      :close-on-click-overlay="false"
      class="sign-div"
  >
    <p style="text-align: center;font-size: 16px;">{{ $t('common_sign') }}</p>
    <vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
               :bgColor.sync="bgColor"/>
    <div class="btn-div">
      <button class="reset-sign" @click="resetSign">{{ $t('common_reset') }}</button>
      <button class="confirm-sign" @click="confirmSign">{{ $t('common_confirm') }}</button>
    </div>
  </van-popup>
</template>

<script>
import {userService} from '@/services';
import store from '@/store';

export default {
  name: 'CreatePayLinkDialog',
  props: ['visible', 'next'],
  data() {
    return {
      showSign: this.visible,
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      isCrop: false,
      signImage: null
    };
  },
  watch: {
    visible() {
      this.showSign = this.visible;
    },
    showSign() {
      this.$emit('update:visible', this.showSign);
    },
  },
  methods: {
    resetSign() {
      this.$refs['esign'].reset() //清空画布
    },
    confirmSign() {
      this.$refs['esign'].generate().then(res => {
        this.signImage = res // 得到了签字生成的base64图片
        this.uploadSignatureImg();
      }).catch(() => this.$toast(this.$t('common_unsign')));
    },
    uploadSignatureImg() {
      if (!this.signImage) {
        this.$toast(this.$t('common_unsign'))
      }
      userService.uploadSignatureImg({img: this.signImage})
          .then(() => {
            this.showSign = false
            store.getUser(true)
            if (this.next) {
              this.next();
            }
          })
    }
  },
};
</script>
<style lang="less" scoped>
.sign-div {
}

.btn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.confirm-sign, .reset-sign {
  margin: 20px;
  background-color: #2969FF;
  color: #ffffff;
  border-radius: 10px;
  padding: 5px 10px;
  border: none;
}
</style>
