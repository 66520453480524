import axios from "@/common/axios";

export default {
    async apply(params) {
        return await axios.post("/loan/apply", params)
    },
    async getOrderList() {
        return await axios.post("/loan/getUserOrderList")
    },
    async getOrderInfo(params) {
        return await axios.post("/loan/orderInfo", params)
    },
    async confirmWithdraw(params) {
        return await axios.post("/user/withdraw", params)
    },
    async getLastWithdrawError() {
        return await axios.post("/user/getLastWithdrawError")
    },
    async getLoanProgress() {
        return await axios.post("/loan/getLoanProgress")
    },
    async recharge(params) {
        return await axios.post("/loan/recharge", params)
    },
}