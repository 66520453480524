import {userService} from '@/services';
import {appService} from "@/services";
import {validateURL} from "@/common/validate";

export default {
    title: '',
    loadingRef: 0,
    user: null,
    toggle: false,
    channel: '',
    modifiedUser: null,
    appList: null,
    showContact: false,
    withdrawAmount: null,
    withdrawPassword: null,
    withdrawBankcard: null,
    setToken(token) {
        window.localStorage.setItem('token', token);
    },
    setUnlockToken(token) {
        window.sessionStorage.setItem('unlockToken', token);
    },
    get(key) {
        return this[key];
    },
    commit(key, value) {
        this[key] = value;
    },
    updateTitle(title) {
        this.title = title;
    },
    addLoadingRef() {
        this.loadingRef += 1;
    },
    minusLoadingRef() {
        if (this.loadingRef > 0) {
            this.loadingRef -= 1;
        }
    },
    updateUser(user) {
        this.user = user;
        this.modifiedUser = JSON.parse(JSON.stringify(user))
    },
    async getUser(refresh = false) {
        if (!this.user || refresh) {
            this.user = await userService.getUserInfo();
        }
        if (!this.modifiedUser) {
            this.modifiedUser = Object.assign({}, this.user)
        }
        return this.user;
    },
    updateModifiedUser(user) {
        this.modifiedUser = user;
    },
    async getModifiedUser() {
        if (!this.modifiedUser) {
            this.modifiedUser = JSON.parse(JSON.stringify(await this.getUser()))
        }
        return this.modifiedUser;
    },
    async getConfig(configName) {
        if (!this.get(configName.toLowerCase())) {
            await this.initConfig()
        }
        return this.get(configName.toLowerCase())
    },
    async initConfig() {
        await userService.getAllCompanyConfigs();
    },
    async getAppList() {
        if (!this.appList) {
            this.appList = await appService.getAppList();
        }
        return this.appList;
    },
    async contactUs() {
        // await window.fbq('track', 'AddToWishlist');
        let contactAccount = await appService.getCustomerService();
        if (validateURL(contactAccount)) {
            window.open(contactAccount, "_blank");
        }
    }
};
