<template>
  <div class="page">
    <header-bar :title="$t('repay_detail')" :custom-back="goHome"/>
    <div class="order-info">
      <div class="order-detail">
        <div class="order-detail-item">
          <span class="desc">{{ $t('common_order_id') }}:</span>
          <span class="value">{{ orderInfo.orderId }}</span>
        </div>
        <div class="order-detail-item">
          <span class="desc">{{ $t('common_apply_amount') }}:</span>
          <span class="value">{{ orderInfo.principal }}</span>
        </div>
        <div class="order-detail-item">
          <span class="desc">{{ $t('common_period_days') }}:</span>
          <span class="value">{{ orderInfo.periodDays }}</span>
        </div>
        <div class="order-detail-item">
          <span class="desc">{{ $t('bank_account_name') }}:</span>
          <span class="value">{{ orderInfo.realName }}</span>
        </div>
        <div class="order-detail-item">
          <span class="desc">{{ $t('common_apply_datetime') }}:</span>
          <span class="value">{{ orderInfo.applyTime && orderInfo.applyTime.split(" ")[0] }}</span>
        </div>
      </div>
    </div>
    <div class="period-list" v-if="orderInfo.periodViews && orderInfo.periodViews.length">
      <div class="period-item" v-for="(item, index) of orderInfo.periodViews" :key="index">
        <span class="date">{{ item.period }}</span>
        <div class="line-div">
          <span class="circle"></span>
          <span class="line"></span>
        </div>
        <div class="info-div">
          <span class="value">{{ item.amt }}</span>
          <span class="desc">{{ $t('repay_amount') }}</span>
        </div>
        <div class="status">
          <span :class="`status_${item.status}`"> {{ $t(`period_status_${item.status}`) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {orderService} from "@/services";

export default {
  name: "RepayDetail",
  data() {
    return {
      orderInfo: {},
    }
  },
  mounted() {
    this.getOrderInfo();
  },
  methods: {
    async getOrderInfo() {
      this.orderInfo = await orderService.getOrderInfo({})
    },
    goHome() {
      this.$router.push("/")
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 44px);
  padding-top: 44px;
  position: relative;

  .order-info {
    background: #ffffff;
    margin: 15px 10px;
  }

  .order-detail {
    .order-detail-item {
      display: flex;
      flex-direction: row;
      padding: 10px 15px;

      &:last-child {
        border: none;
      }

      span {
        flex: 1;
      }

      .value {
        color: #333333;
        font-size: 16px;
        text-align: right;
      }

      .desc {
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .period-list {
    background: #ffffff;
    margin: 15px 10px;
    padding: 20px 0 10px;

    .period-item {
      display: flex;
      flex-direction: row;
      padding: 0 15px;
      color: #333333;
      justify-content: space-around;

      &:last-child {
        .line-div {
          .line {
            width: 0;
          }
        }
      }

      .date {
        font-size: 14px;
      }

      .line-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;

        .circle {
          margin-top: 2px;
          width: 8px;
          height: 8px;
          border: 2.5px solid @base-color;
          border-radius: 8px;
        }

        .line {
          flex-grow: 1;
          width: 1px;
          background: #CBC2C2;
        }
      }

      .info-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .value {
          font-size: 14px;
        }

        .desc {
          margin-top: 5px;
          font-size: 12px;
          color: #999999;
        }
      }

      .status {
        margin: 0 10px 15px;
        display: flex;
        align-items: center;
        span {
          display: block;
          font-size: 12px;
          padding: 5px 12px;
          border-radius: 3px;
          min-width: 45px;
          text-align: center;
        }

        .status_0 {
          background: #74747433;
          color: #6A6A6A;
        }

        .status_1 {
          background: #2A7BFF33;
          color: #2A7BFF;
        }

        .status_2 {
          background: #F56C6C33;
          color: #F56C6C;
        }
      }
    }
  }
}
</style>