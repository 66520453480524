import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import i18n from '@/i18n';
import '@vant/touch-emulator';
import './components';
import '@/fortawesome';
import './plugins/element';
import './styles/styles.scss';
import 'vant/lib/index.less';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import vueEsign from 'vue-esign'

Vue.use(vueEsign);
import lang from 'element-ui/lib/locale/lang/th';
import locale from 'element-ui/lib/locale';

locale.use(lang);
import {
    Toast, Button, Step, Steps, Cell, CellGroup, Field, Picker, Icon, Popup, DatetimePicker, Empty,
    Dialog, Uploader, Checkbox, CheckboxGroup, Tabbar, TabbarItem, Divider, Slider, Overlay, RadioGroup, Radio
} from 'vant';

Vue.use(Button).use(Step).use(Steps).use(Cell).use(CellGroup).use(Field).use(Picker).use(Icon).use(Popup).use(DatetimePicker)
    .use(Dialog).use(Uploader).use(Checkbox).use(CheckboxGroup).use(Tabbar).use(TabbarItem).use(Toast).use(Divider)
    .use(Slider).use(Overlay).use(RadioGroup).use(Radio).use(Empty).use(Viewer, {
    defaultOptions: {
        zIndex: 1, // 层级
        inline: false, // 启用 inline 模式
        button: true, // 显示右上角关闭按钮
        navbar: false, // 显示缩略图导航
        title: false, // 显示当前图片标题
        toolbar: false, // 工具栏
        tooltip: true, // 显示缩放百分比
        movable: true, // 图片是否可以移动
        zoomable: true, // 图片是否可以缩放
        rotatable: false, // 图片是否可旋转
        scalable: false, // 图片是否可翻转
        transition: true, // css3过度
        fullscreen: true, // 播放时是否全屏
        keyboard: false, // 是否支持键盘
        loop: false, // 图片是否循环播放
    }

});

import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
