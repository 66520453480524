<template>
  <div class="apply-div">
    <header-bar :title="$t('apply_title')" color="#ffffff" background-color="rgba(255, 255, 255, 0)"/>
    <!--    <div class="app-info">-->
    <!--      <img class="logo" :src="app.logo">-->
    <!--      <span class="name">{{ app.name }}</span>-->
    <!--    </div>-->
    <div class="loan-detail">
      <div class="amount-range">
        <span class="desc">{{ $t('common_apply_amount') }}: &nbsp;</span>
        <span>{{ `${minAmount}-${maxAmount}` }}₱</span>
      </div>
      <div class="loan-amount">
        ₱
        <van-field v-model.number="selectedAmount" type="digit" autocomplete="false" class="input"
                   @blur="amtFormatter" maxlength="7"
                   required :placeholder="$t('common_input_placeholder')"/>
      </div>
    </div>
    <div class="periodDays">
      <span class="title">{{ $t('common_time_limit') }}:</span>
      <div class="range-content">
        <div class="range-item" :class="{'selected': selectedPeriod === item}"
             v-for="(item, index) of periodDays" :key="index" @click="selectPeriod(item)">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="title" v-show="selectedPeriod&&selectedAmount">{{ $t('confirm_apply_header') }}:</div>
    <div class="order-detail" v-show="selectedPeriod&&selectedAmount">
      <div class="order-detail-item">
        <span class="desc">{{ $t('interest_rate') }}:</span>
        <span class="value">{{ rateFormatter(rate) }}</span>
      </div>
      <div class="order-detail-item">
        <span class="desc">{{ $t('common_interest') }}:</span>
        <span class="value">{{ interest }}</span>
      </div>
      <div class="order-detail-item">
        <span class="desc">{{ $t('pay_per_period') }}:</span>
        <span class="value">{{ shouldRepayPerMonth }}</span>
      </div>
      <div class="order-detail-item">
        <span class="desc">{{ $t('apply_repay_amount') }}:</span>
        <span class="value">{{ shouldRepay }}</span>
      </div>
    </div>
    <div class="bottom"></div>
    <contact-custom-service top="35px" right="0"/>
    <div class="apply-btn">
      <van-button block @click="apply" :loading="loading" :disabled="!selectedAmount || !selectedPeriod">
        {{ $t('common_submit') }}
      </van-button>
    </div>
    <van-overlay :show="showContact" class-name="contact-box">
      <div class="wrapper" @click.stop>
        <img :src="successImg">
        <span class="contact-title">{{ $t('apply_loan_success') }}</span>
        <div class="btn-div">
          <van-button type="info" block @click="store.contactUs" v-if="store.showContact">
            {{ $t('click_to_contact_service') }}
          </van-button>
          <van-button type="info" block @click="viewOrder" v-else>
            {{ $t('common_check') }}
          </van-button>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showPeriodSelect" position="bottom" round>
      <van-picker show-toolbar
                  :columns="periodDays"
                  @cancel="showPeriodSelect=false"
                  @confirm="selectPeriod"
                  :confirm-button-text="$t('common_confirm')"
                  :cancel-button-text="$t('common_cancel')"/>
    </van-popup>
  </div>
</template>
<script>
import store from "@/store";
import {appService, orderService} from "@/services";
import {rateFormatter} from "@/common/formatter"
import successImg from '@/assets/success.png';
import applyWalletImg from '@/assets/apply-wallet.png';
import applyHeaderImg from '@/assets/apply-header2.png';

export default {
  name: "AppLy",
  data() {
    return {
      applyWalletImg,
      applyHeaderImg,
      successImg,
      showPeriodSelect: false,
      store,
      showContact: false,
      rateFormatter,
      appList: [],
      selectedAmount: null,
      selectedPeriod: null,
      periodConfigs: {},
      amounts: [],
      periodDays: [],
      app: {},
      loading: false,
      minAmount: 80000,
      maxAmount: 3000000,
      interest: 0,
      shouldRepay: 0,
      shouldRepayPerMonth: 0,
      rate: 0,
      loanOrderId: null,
      amountStep: 10000,
      selectedIndex: 0,
    }
  },
  watch: {
    selectedAmount: function (value) {
      this.interest = (this.rate * value / 100000 * this.selectedPeriod).toFixed(1) * 100 / 100;
      this.shouldRepay = this.interest + this.selectedAmount;
      this.shouldRepayPerMonth = (this.shouldRepay / this.selectedPeriod).toFixed(1) * 100 / 100;
    },
    selectedPeriod: function (value) {
      let period = this.periodConfigs.rateConfigs.filter(r => r.periodDays === value)[0];
      this.rate = period.rate;
      this.interest = (this.rate * value / 100000 * this.selectedAmount).toFixed(1) * 100 / 100;
      this.shouldRepay = this.interest + this.selectedAmount;
      this.shouldRepayPerMonth = (this.shouldRepay / this.selectedPeriod).toFixed(1) * 100 / 100;
    },
  },
  mounted() {
    this.getAppList();
    this.getPeriodConfig();
  },
  methods: {
    async viewOrder() {
      this.$router.push("/detail/" + this.loanOrderId);
    },
    amtFormatter() {
      if (this.selectedAmount > this.maxAmount) {
        this.selectedAmount = this.maxAmount;
        return;
      }
      if (this.selectedAmount < this.minAmount) {
        this.selectedAmount = this.minAmount;
      }
    },
    async getAppList() {
      this.appList = await store.getAppList();
      this.app = this.appList.filter(a => a.id === this.$route.params.appId)[0]
    },
    async getPeriodConfig() {
      this.periodConfigs = await appService.getPeriodConfig({appId: this.$route.params.appId});
      this.periodConfigs.rateConfigs.forEach(r => {
        this.periodDays.push(r.periodDays)
      });
      this.periodDays.sort((a, b) => a - b)
    },
    selectPeriod(value) {
      this.selectedPeriod = value;
      this.showPeriodSelect = false;
    },
    apply() {
      let params = {
        amt: this.selectedAmount,
        configId: this.periodConfigs.id,
        periodDays: this.selectedPeriod,
        appId: this.$route.params.appId
      }
      orderService.apply(params)
          .then((res) => {
            this.loanOrderId = res.loanOrderId;
            this.showContact = true;
          })
          .catch(() => this.loading = false);
    }
  }
}
</script>
<style lang="less" scoped>
.apply-div {
  padding-top: 44px;
  min-height: calc(100% - 44px);
  background: url("../../assets/apply-background.png") no-repeat;
  background-position: right bottom;
  background-size: 100%;
  background-color: @base-color;


  .header {
    z-index: 2;
  }

  .app-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 80px;
      height: 80px;
      margin: 20px;
    }

    .name {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .header-background {
    background-color: @base-color;
    height: 120px;
    width: 100%;
    border-radius: 0 0 10% 10%;
    position: relative;

    img {
      position: absolute;
      height: 150%;
      right: 5%;
      bottom: -35%;
      z-index: 3;
    }

  }

  .loan-detail {
    margin: 50px 15px 30px;
    border-radius: 12px;
    position: relative;
    z-index: 5;

    .amount-range {
      color: #ffffff;
      font-size: 16px;
      text-align: left;
      margin-top: 10px;
    }

    .loan-amount {
      background: #ffffff;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 10px 25px;
      border-radius: 10px;
      font-size: 46px;
      display: flex;
      align-items: center;
      font-weight: 700;

      .input {
        padding: 0;

        /deep/ input {
          border-radius: 5px;
          background: #ffffff;
          outline: 0;
          font-size: 46px;
          padding-left: 10px;
          font-weight: 700;
        }
        /deep/ input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 36px;
        }

        /deep/ input:-ms-input-placeholder { /* IE 10+ */
          font-size: 36px;
        }

        /deep/ input::-moz-placeholder { /* Firefox 19+ */
          font-size: 36px;
        }
      }
    }
  }

  .periodDays {
    margin: 15px;
    .title {
      color: #ffffff;
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .range-item {
      min-width: 50px;
      margin-right: 10px;
      background-color: #ffffff;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #989898;
      flex: none;
      padding: 0 10px;
      margin-bottom: 10px;
      text-align: center;
      line-height: 30px;
      border-radius: 8px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .range-content {
    display: flex;
    overflow-x: auto;
  }

  .selected {
    background: #5343F4 !important;
    color: #ffffff !important;

    //&:before {
    //  content: "";
    //  position: absolute;
    //  right: 0;
    //  bottom: 0;
    //  border: 8px solid #314BCE;
    //  border-top-color: transparent;
    //  border-left-color: transparent;
    //}

    //&:after {
    //  content: "";
    //  width: 3px;
    //  height: 6px;
    //  position: absolute;
    //  right: 2px;
    //  bottom: 3px;
    //  border: 1px solid #ffffff;
    //  border-top-color: transparent;
    //  border-left-color: transparent;
    //  transform: rotate(45deg);
    //}
  }

  .select-div {

    .amount-div {
      padding: 10px 15px 5px;
      background-color: #FFFFFF;
      border-radius: 6px;
    }

    .amount-range-desc {
      margin-bottom: 10px;
      display: block;
      color: #333333;
      font-size: 12px;
    }

    .amount-range {
      margin-bottom: 15px;
    }

    .split-line {
      margin-top: 15px;
      background-color: #0000002E;
      height: 1px;
    }

    .custom-button {
      height: 8px;
      width: 8px;
      border-radius: 10px;
      border: 4px solid #FAAD14;
      background: #FFFFFF;
    }

    .value-rang {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      font-size: 12px;
      color: #83848B;

      .min-value {
        text-align: left;
      }

      .max-amount {
        text-align: right;
      }
    }

    /deep/ .radio-group {
      .el-radio-button {
        margin-right: 20px;
        margin-bottom: 10px;

        &.is-active {
          .el-radio-button__inner {
            background: @button-color;
          }
        }

        .el-radio-button__inner {
          border-radius: 15px;
          box-shadow: none;
          border: none;
          background: rgba(118, 129, 208, 0.26);
          color: #83848B;
          line-height: 26px;
          text-align: center;
          padding: 0 16px;
        }
      }
    }

    .title {
      display: block;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .selected-amount {
      display: block;
      color: #FAAD14;
      font-size: 32px;
      font-weight: 700;
      margin: 8px 0 15px;
    }
  }
  div.title {
    margin-left: 15px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .order-detail {
    margin: 15px;
    background-color: #FFFFFF;
    border-radius: 6px;

    .order-detail-item {
      display: flex;
      flex-direction: row;
      padding: 10px;
      border-bottom: 1px solid #EEEEEE;
      margin: 0 10px;
      height: 40px;
      justify-content: center;
      align-items: center;
      &:last-child {
        border: none;
      }


      .value {
        flex: 1;
        color: #1B1B1A;
        font-weight: 700;
        font-size: 16px;
        text-align: right;
      }

      .desc {
        flex: 2;
        color: #1B1B1A;
        font-size: 16px;
      }
    }
  }

  .bottom {
    height: 80px;
  }

  .apply-btn {
    position: fixed;
    width: 100%;
    bottom: 10px;
    display: flex;
    align-items: center;

    /deep/ .van-button {
      margin: 20px;
      border-radius: 6px;
      font-size: 16px;
      background: @button-color;
      color: #ffffff;
      height: 45px;
      border: none;
    }
  }

  .contact-box {
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 25px 20px;
      border-radius: 6px;
      font-size: 12px;

      img {
        width: 40%;
        margin-bottom: 10px;
      }

      .contact-title {
        font-size: 26px;
        font-weight: 600;
        color: #191919;
        margin: 8px 0 3px;
      }

      .btn-div {
        display: flex;
        flex-direction: row;
      }

      /deep/ .van-button {
        height: 45px;
        width: 150px;
        font-size: 16px;
        background: @button-color;
        border-radius: 8px;
        margin: 15px 10px 0;
      }
    }
  }
}
</style>