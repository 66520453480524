import store from '@/store';

export function rateFormatter(cellValue) {
    return cellValue && `${(cellValue / 1000.0).toFixed(3) * 1000 / 1000}%`;
}

export function applicationFormatter(row, column, cellValue) {
    const application = store.applicationList.find(
        (item) => item.id === cellValue,
    );
    return application && application.name;
}

export function cornerFormatter(row, column, currency) {
    return (
        currency && simpleCurrencyFormatter(currency / 100)
    );
}

export function currencyFormatter(row, column, currency) {
    return simpleCurrencyFormatter(currency);
}

export function simpleCurrencyFormatter(currency) {
    currency = Number(currency);
    return (
        currency &&
        ((currency.toFixed(2) * 100) / 100)
            .toLocaleString()
    );
}

export function simpleCurrencyFormatter2(row, column, currency) {
    if (currency) {
        return Number(currency).toLocaleString();
    }
}

export function myIsNaN(value) {
    return typeof value === 'number' && !isNaN(value);
}

export function percentFormatter(row, column, cellValue) {
    return simplePercentFormatter(cellValue);
}

export function simplePercentFormatter(value) {
    return value && `${(value * 100).toFixed(1)}%` || "0.0%";
}

//判断是否加百分号--只取小数点2位
export function addPercent2(row, column, value) {
    if (myIsNaN(Number(value)) || value == 0) {
        return `${Number(value).toFixed(2)}%`;
    } else {
        return value;
    }
}

export function dateFormatter(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
        }
    }
    return fmt;
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

//传时间戳，显示年月日时分秒
export function formatTime(time) {
    let date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
}

//传时间戳，显示年月日
export function formatTime2(row, column, time) {
    let date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
}

const formatNumber = (n) => {
    const str = n.toString();
    return str[1] ? str : '0' + str;
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return [year, month, day].map(formatNumber).join('-');
}

export function formatDateBegin(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = 1;
    return [year, month, day].map(formatNumber).join('-');
}

export function mapOrderStatusDesc(status) {
    if (status < 3) {
        return 'common_status_auditing';
    }
    if (status === 3) {
        return 'ssd_17';
    }
    if (status === 5 || status === 12) {
        return 'has_order_refuse';
    }
    if (status === 4) {
        return 'has_order_repay'
    }
    return 'status_' + status
}

export function mask(str) {
    return maskWithLength(str, 1, 1)
}

export function maskWithLength(str, frontLen, endLen) {
    const len = str.length - frontLen - endLen;
    let replaceStr = '';
    for (let i = 0; i < len; i++) {
        replaceStr += '*'
    }
    return str.substring(0, frontLen) + replaceStr + str.substring(str.length - endLen)
}

