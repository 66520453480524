<template>
  <div class="page">
    <header-bar :title="$t('contract')"/>
    <div v-viewer class="contract">
      <img :src="loanProgress.contract">
    </div>
  </div>
</template>
<script>

import {orderService} from "@/services";

export default {
  name: "ContractPage",
  data() {
    return {
      loanProgress: {},
    }
  },
  async activated() {
    this.loanProgress = await orderService.getLoanProgress();
  },
}
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 44px);
  padding-top: 44px;

  .contract {
    margin: 30px;

    img {
      width: 100%;
    }
  }
}
</style>
