<template>
  <div class="page">
<!--    <div class="login-header">-->
<!--      <img :src="isRegister?headerImg2:headerImg"/>-->
<!--    </div>-->
    <div class="form-div">
      <div class="title">
        <span class="welcome">{{ isRegister ? $t('common_register') : $t('login_title') }}</span>
      </div>
      <div class="form-item mobile-div">
        <van-field type="tel" v-model="mobile" class="input" :formatter="formatterNumber" maxlength="11"
                 :class="{'register-input': isRegister}"  :placeholder="$t('common_phone')"/>
      </div>
      <div class="form-item">
        <van-field class="input" :type="showPassword? 'text' : 'password'" v-model="password"
                   :placeholder="$t('common_password')">
          <template #button>
            <span @click="() => showPassword = !showPassword">
               <van-icon name="closed-eye" v-if="showPassword"/>
               <van-icon name="eye-o" v-else/>
            </span>
          </template>
        </van-field>
      </div>
      <div class="form-item" v-if="isRegister">
        <van-field class="input" :type="showConfirmPassword? 'text' : 'password'" v-model="passwordConfirm"
                   :placeholder="$t('confirm_password')">
          <template #button>
            <span @click="() => showConfirmPassword = !showConfirmPassword">
               <van-icon name="closed-eye" v-if="showConfirmPassword"/>
               <van-icon name="eye-o" v-else/>
            </span>
          </template>
        </van-field>
      </div>
      <div class="form-item" v-if="isRegister">
        <van-field class="input" type="text" v-model="telemarketingCode" :placeholder="$t('invite_code')">
          <!--          <template #button>-->
          <!--            <van-button class="otp-btn" :loading="loadingCode" :disabled="!canGetOtp" :class="{'disabled': !canGetOtp}"-->
          <!--                        size=small @click="getOtp">-->
          <!--              {{ canGetOtp ? $t("common_captcha") : `${countDownTime}s` }}-->
          <!--            </van-button>-->
          <!--          </template>-->
        </van-field>
      </div>
      <div class="bottom">
        <div class="btn-div">
          <van-button type="warning" :loading="loading" :disabled="!canPost" :class="{'disabled': !canPost}" class="btn"
                      @click.native="login" block>
            {{ isRegister ? $t('common_register') : $t('login_title') }}
          </van-button>
        </div>
        <div class="tips-btn-div">
          <span class="left-tips">{{ isRegister ? $t('registered') : $t('not_registered') }}?&nbsp;</span>
          <a class="tips-btn" @click="changeType">{{ isRegister ? $t('login_title') : $t('common_register') }} </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerImg from '../assets/lock2.png';
import headerImg2 from '../assets/register.png';
import welcomeImg from '../assets/welcome-split.png';
import store from '@/store';
import axios from '@/common/axios';
import {validateMobile} from '@/common/validate';

export default {
  name: "IndexPage",
  data() {
    return {
      welcomeImg,
      headerImg,
      headerImg2,
      countDownTime: 60,
      showPassword: false,
      showConfirmPassword: false,
      mobile: '',
      canGetOtp: true,
      placePhone: this.$t('common_phone_input_placeholder'),
      placePassword: this.$t('common_password_input_placeholder'),
      placePasswordConfirm: this.$t('common_password_confirm_placeholder'),
      show: false,
      loading: false,
      loadingCode: false,
      password: null,
      passwordConfirm: null,
      isRegister: false,
      appName: process.env.DEPLOY_CONFIGS.title,
      telemarketingCode: ''
    }
  },
  async activated() {
    this.isRegister = this.$route.path.endsWith('/register');
  },
  computed: {
    canPost() {
      if (this.isRegister) {
        return this.mobile && this.mobile.toString().length == 11 && this.password && this.passwordConfirm && this.telemarketingCode;
      } else {
        return this.mobile && this.password;
      }
    },
    title() {
      if (this.isRegister) {
        return this.$t('common_register')
      } else {
        return this.$t('login_title')
      }
    }
  },
  methods: {
    formatterNumber(value) {
      if (this.isRegister) {
        return value && value.replace(/\D/g, '');
      }
      return value;
    },
    changeType() {
      if (this.isRegister) {
        this.$router.push('/login');
      } else {
        this.$router.push('/register');
      }
      this.isRegister = !this.isRegister;
    },
    async getOtp() {
      if (!validateMobile(this.mobile)) {
        this.$toast(this.$t('phone_input'));
        return;
      }
      if (this.isRegister) {
        if (this.password !== this.passwordConfirm) {
          this.$toast(this.$t('common_password_not_match'));
          return;
        }
      }
      let params = {
        mobile: this.mobile,
        appId: process.env.DEPLOY_CONFIGS.appId,
        companyId: process.env.DEPLOY_CONFIGS.companyId,
      }
      this.loadingCode = true;
      axios.post(`/sms/${this.isRegister ? 'register' : 'login'}`, params)
          .then(() => {
            this.loadingCode = false
            this.codeCountDown();
          })
          .catch(() => this.loadingCode = false);
    },
    codeCountDown() {
      this.canGetOtp = false;
      this.countDownTime = 60
      let time = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 1) {
          this.canGetOtp = true
          this.countDownTime = 60
          clearInterval(time)
        }
      }, 1000);
    },
    async login() {
      if (this.isRegister) {
        if (!validateMobile(this.mobile)) {
          this.$toast(this.$t('phone_input'));
          return;
        }
        if (this.password !== this.passwordConfirm) {
          this.$toast(this.$t('common_password_not_match'));
          return;
        }
      }
      let params = {
        mobile: this.mobile,
        // captcha: this.captcha,
        installationSource: localStorage.getItem('channel'),
        platform: "h5",
        password: this.password,
        companyId: process.env.DEPLOY_CONFIGS.companyId,
        appId: process.env.DEPLOY_CONFIGS.appId,
        telemarketingCode: this.telemarketingCode
      }
      if (this.canPost) {
        this.loading = true;
        axios.post(`/user/${this.isRegister ? 'register' : 'login'}`, params)
            .then((login) => {
              this.loading = false;
              store.commit('mobile', this.mobile);
              store.updateUser(null)
              store.setToken(login.token);
              this.$router.push('/');
            })
            .catch(() => this.loading = false);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  padding: 20px 10px;
  background: @base-color;

  .login-header {
    margin-top: 20px;
    img {
      display: block;
      width: 60%;
      margin: auto;
    }
  }

  .form-div {
    padding-bottom: 45px;
    margin-top: 45px;
    border-radius: 8px;

    .title {
      color: #ffffff;
      text-align: center;
      padding-bottom: 55px;

      .welcome {
        font-size: 42px;
        font-weight: 700;
        display: block;
      }
    }

    .welcome-div {
      padding: 0 55px 0;

      img {
        width: 100%;
      }
    }

    .mobile-div.form-item {
      display: flex;
      background-color: #ffffff;
      border-radius: 5px;
      margin: 15px 15px 0;
      padding: 0;

      .prefix {
        font-size: 14px;
        color: #333333;
        padding-left: 10px;
      }

      .input.register-input {
        /deep/ input {
        }
      }
    }

    .form-item {
      padding: 15px 15px 0;
      text-align: left;
      align-items: center;
      margin-bottom: 5px;

      .input-div {
        position: relative;
      }

      /deep/ .otp-btn {
        background-color: @button-color;
        border-radius: 5px;
        color: #FFFFFF;
      }

      /deep/ .van-field__button {
        padding-right: 10px;
      }

      .input-desc {
        font-size: 16px;
        color: #111111;
        display: block;
        margin-bottom: 10px;
      }

      .input {
        padding: 0;
        border-radius: 5px;

        /deep/ .van-cell__value {
          border: none;
          background: #ffffff;
        }

        /deep/ input {
          background: #ffffff;
          display: block;
          width: calc(100% - 10px);
          outline: 0;
          height: 45px;
          color: #111111;
          font-size: 14px;
          padding-left: 10px;
        }

        /deep/ input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #707070;
        }

        /deep/ input:-ms-input-placeholder { /* IE 10+ */
          color: #707070;
        }

        /deep/ input:-moz-placeholder { /* Firefox 18- */
          color: #707070;
          opacity: 1;
        }

        /deep/ input::-moz-placeholder { /* Firefox 19+ */
          color: #707070;
          opacity: 1;
        }

        /deep/ input:-webkit-autofill,
        /deep/ input:-webkit-autofill:hover,
        /deep/ input:-webkit-autofill:focus,
        /deep/ input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: 35px;
  }

  .btn-div {
    margin: 0 15px 10px;

    /deep/ .btn {
      background: @button-color;
      color: #ffffff;
      border-radius: 8px;
      border: none;
      height: 45px;
      font-size: 16px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .tips-btn-div {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 25px 0;

    .left-tips {
      display: block;
      margin-bottom: 10px;
    }

    .tips-btn {
      text-decoration: underline;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 24px;
    }
  }
}
</style>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>