<template>
  <div class="header"
       :style="[{backgroundColor: backgroundColor? backgroundColor:''},{ color: color?color: ''},
       {fontWeight: fontWeight? fontWeight:'300'}, {fontSize: fontSize? fontSize:'16px'}]">
    <div class="back-btn" v-if="!hideBack" @click="back">
      <i class="el-icon-arrow-left"></i>
    </div>
    <span class="title">{{ title }}</span>
    <div class="action">
      <slot>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderBar",
  props: ['hideBack', 'title', 'customBack', 'backgroundColor', 'color', 'fontWeight', 'fontSize'],
  methods: {
    back() {
      if (this.customBack) {
        this.customBack();
      } else {
        this.$router.back();
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: @base-color;
  color: #ffffff;
  z-index: 999;

  .back-btn {
    width: 44px;
    align-items: center;
    position: fixed;
    height: 44px;
    display: flex;
    text-align: center;
    justify-content: center;

    i {
    }
  }

  .title {
    text-align: center;
    margin: auto;
    align-items: center;
  }
}

.action {
  display: flex;
  position: fixed;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  width: 44px;
  height: 44px;
}
</style>