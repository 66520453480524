<template>
  <div class="bankcard">
    <header-bar :title="$t('common_bank_account')"/>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">{{ $t('common_name') }}</span>
        <div class="item-value-div">
          <span class="item-value">
            {{ user.realName }}
          </span>
          <i/>
        </div>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('common_bank') }}</span>
        <div class="item-value-div">
          <span class="item-value">
            {{ bankName }}
          </span>
        </div>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('common_bank_account') }}</span>
        <div class="item-value-div">
          <el-input class="item-value" :readonly="true" v-model="cardNo" :placeholder="$t('common_bank_account')"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import bank from '@/assets/bank.json';

export default {
  name: 'BankInfoDetail',
  data() {
    return {
      bankName: '',
      cardNo: '',
      user: {},
      loading: false,
      showBankSelect: false,
      bankList: bank.map(item => item.bankName),
    }
  },
  computed: {
    disabled() {
      return this.bankName && this.user.bankName === this.bankName
          && this.cardNo && this.user.cardNo === this.cardNo
          || !this.bankName || !this.cardNo
    },
  },
  async mounted() {
    this.user = await store.getUser();
    this.bankName = this.user.bankName;
    this.cardNo = this.user.cardNo;
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.bankcard {
  padding-top: 44px;
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 44px);
}

.user-info {
  margin: 20px 10px;
  background: #FFFFFF;
  padding: 10px;

  .info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 14px;
    border-bottom: 1px dashed silver;

    .item-desc {
      flex: 1;
      color: #666666;
    }

    .item-value-div {
      flex: 1;
      align-items: center;
      text-align: right;
      display: flex;
      justify-content: right;

      i {
        width: 20px;
      }
    }

    .item-value {
      /deep/ input {
        text-align: right;
        outline: 0;
        border: none;
        padding: 0;
      }
    }

    span.item-value {
      display: block;
      color: #606266;
      line-height: 30px;
    }
  }
}

.apply-btn {
  margin: 30px;
}
</style>