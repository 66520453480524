import axios from "@/common/axios";
import store from "@/store";

export default {
    async getAppList() {
        let appList = await axios.post('/loan/appList');
        store.commit("appList", appList);
        return appList;
    },
    async getPeriodConfig(params) {
        return await axios.post('/loan/getPeriodConfig', params);
    },
    async getCustomerService() {
        return await axios.post('/loan/getCustomerService', {appId: process.env.DEPLOY_CONFIGS.appId});
    },
}