<template>
  <div class="bankcard">
    <header-bar :title="$t('base_info_title')"/>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">{{ $t('bank_account_name') }}</span>
        <span class="item-value readonly">
          {{ modifiedUser.realName }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('common_bank') }}</span>
        <el-input class="item-value" v-model="modifiedUser.bankName" :disabled="!!user.bankName"
                  :class="{'readonly': !!user.bankName }" :placeholder="$t('common_bank_name')"/>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('bank_account_no') }}</span>
        <el-input class="item-value" v-model="modifiedUser.cardNo" :disabled="!!user.cardNo&& !couldModify"
                  :class="{'readonly': !!user.cardNo&& !couldModify }" :placeholder="$t('common_bank_account')"
                  :type="showPassword? 'text':'password'" ref="password"
        >
          <img class="eye" slot="suffix" :src='showPassword? closeEyeIcon : openEyeIcon' @click="togglePassword">
        </el-input>
      </div>
    </div>
    <div class="apply-btn">
      <van-button type="info" block @click="goNext" :loading="loading" :disabled="disabled">
        {{ $t('common_submit') }}
      </van-button>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {userService} from "@/services";

export default {
  name: 'BankInfo',
  data() {
    return {
      modifiedUser: {},
      loading: false,
      user: {},
      couldModify: false,
      showPassword: false,
      closeEyeIcon: require('@/assets/eye-close.png'),
      openEyeIcon: require('@/assets/eye-open.png')
    }
  },
  computed: {
    disabled() {
      return !this.modifiedUser.bankName || !this.modifiedUser.cardNo
    },
  },
  async mounted() {
    this.modifiedUser = await store.getModifiedUser();
    this.user = await store.getUser();
    this.couldModify = !this.user.userQualificationView.bankCard;
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    async goNext() {
      let params = {
        bankName: this.modifiedUser.bankName,
        cardNo: this.modifiedUser.cardNo,
      }
      userService.updateBankcard(params)
          .then(() => {
            this.loading = false;
            store.updateModifiedUser(this.modifiedUser)
            store.getUser(true)
            this.$router.push("/loan")
          })
          .catch(() => this.loading = false);
    }
  }
}
</script>
<style lang="less" scoped>
.bankcard {
  padding-top: 44px;
  padding-bottom: 44px;
}

.readonly {
  background-color: #F5F7FA !important;
  border-color: #E4E7ED !important;
  color: #C0C4CC !important;
}

.user-info {
  margin: 10px;

  .info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .item-desc {
      color: #666666;
    }

    .item-value {
      width: 200px;
    }

    span.item-value {
      width: 200px;
      background-color: #FFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #C0C4CC;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      overflow-x: auto;
      overflow-y: hidden;

      &.placeholder {
        color: #C0C4CC;
      }
    }
  }
}

.title-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .split-line {
    background-color: #8A929E;
    height: 1px;
    line-height: 1px;
    display: inline-block;
    width: 100px;
  }

  .title {
    color: #8A929E;
  }
}

.apply-btn {
  position: absolute;
  width: 100%;
  bottom: 0;
}

/deep/.el-input__suffix-inner {
  display: flex;
  align-items: center;
  height: 100%;
}
.eye {
  height: 22px;
  display: block;
}
</style>