<template>
  <div class="id-card">
    <header-bar/>
    <div class="title">
      <img :src="questionImg"/>
      <div class="title-desc">
        <span>Basic</span>
        <span>Information</span>
      </div>
    </div>
    <div class="page">
      <div class="img-list">
        <div class="identity-img-upload" v-for="(item, index) in identityList"
             :key="index">
          <div class="desc">
            <span class="index"> {{ index + 1 }}</span>
            <span class="img-desc">
          {{ $t(item.desc) }}
          </span>
          </div>
          <el-upload
              :disabled="!needUploadIdentity"
              class="uploader"
              accept="image/*"
              action="#"
              :http-request="(data)=> uploadImg(data, item )"
              :show-file-list="false"
              :before-upload="beforeUpload">
            <img v-if="item.url" :src="item.url" class="identity-img">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="user-info">
        <div class="info-item">
          <span class="item-desc">{{ $t('base_info_name') }}:</span>
          <el-input class="item-value" v-model="modifiedUser.realName"
                    :disabled="!!user.realName && !needUploadIdentity"
                    :class="{'readonly': !!user.realName && !needUploadIdentity}"
                    :placeholder="$t('base_info_name_input_placeholder')"/>
        </div>
        <div class="info-item">
          <span class="item-desc">{{ $t('base_info_id_number') }}:</span>
          <el-input class="item-value" v-model="modifiedUser.identity"
                    @input="(value) => modifiedUser.identity = value.replace(/[^\d]/g,'')"
                    :disabled="!!user.identity && !needUploadIdentity"
                    :class="{'readonly': !!user.identity && !needUploadIdentity }"
                    :placeholder="$t('base_info_id_number_input_placeholder')"/>
        </div>
        <div class="radio-div">
          <span class="item-desc"> {{ $t('base_info_gender') }}:</span>
          <van-radio-group v-model="modifiedUser.sex" :disabled="!!user.sex && !needUploadIdentity"
                           class="item-value radio"
                           direction="horizontal">
            <van-radio :name="1">{{ $t('base_info_gender_male') }}</van-radio>
            <van-radio :name="2">{{ $t('base_info_gender_female') }}</van-radio>
          </van-radio-group>
        </div>
        <div class="info-item">
          <span class="item-desc">{{ $t('base_info_birthday') }}:</span>
          <div class="select-div"
               :class="{'placeholder': !modifiedUser.birthday, 'readonly': !!user.birthday && !needUploadIdentity}">
            <span class="item-value" @click="showBirthdaySelect=!user.birthday || needUploadIdentity">
              {{ modifiedUser.birthday || $t('base_info_birthday_select_placeholder') }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="info-item">
          <span class="item-desc"> {{ $t('base_info_marriage') }}:</span>
          <div class="select-div"
               :class="{'placeholder': !modifiedUser.marriage, 'readonly': !!user.marriage&& !needUploadIdentity}">
            <span class="item-value"
                  @click="showMarriageSelect=!user.marriage || needUploadIdentity">
              {{ modifiedUser.marriage || $t('base_info_choose_marriage_state') }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <van-button class="submit-btn" type="info" block @click="goNext" :loading="loading" :disabled="disabled">
        Next
      </van-button>
      <van-popup v-model="showMarriageSelect" position="bottom">
        <van-picker show-toolbar
                    :columns="marriageList"
                    @cancel="showMarriageSelect=false"
                    @confirm="selectMarriage"
                    :confirm-button-text="$t('common_confirm')"
                    :cancel-button-text="$t('common_cancel')"/>
      </van-popup>
      <van-popup v-model="showBirthdaySelect" position="bottom" round>
        <van-datetime-picker type="date"
                             :title="$t('base_info_birthday_select_placeholder')"
                             @cancel="showBirthdaySelect=false"
                             @confirm="confirmBirthday"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :confirm-button-text="$t('common_confirm')"
                             :cancel-button-text="$t('common_cancel')"/>
      </van-popup>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {formatDate} from '@/common/formatter'
import {userService} from "@/services";
import questionImg from '../../assets/question.png';

export default {
  name: 'IdentityPage',
  data() {
    return {
      questionImg,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      modifiedUser: {},
      identityList: [{
        name: 'frontImage',
        desc: 'id_card_upload_front',
        url: require('@/assets/identity.png'),
        file: null
      }, {
        name: 'backImage',
        desc: 'id_card_upload_back',
        url: require('@/assets/identity-back.png'),
        file: null
      }, {
        name: 'handHoldImage',
        desc: 'id_card_upload_hold',
        url: require('@/assets/identity-handhold.png'),
        file: null
      }],
      loading: false,
      holdFormData: new FormData(),
      showBirthdaySelect: false,
      needUploadIdentity: true,
      showMarriageSelect: false,
      marriageList: [],
      user: {},
    }
  },
  computed: {
    disabled() {
      return this.needUploadIdentity && this.identityList.filter(r => !r.file).length > 0
          || !this.modifiedUser.realName || !this.modifiedUser.identity || !this.modifiedUser.sex
          || !this.modifiedUser.birthday || !this.modifiedUser.marriage
    }
  },
  async mounted() {
    this.modifiedUser = await store.getModifiedUser();
    this.user = await store.getUser();
    this.needUploadIdentity = this.user.userQualificationView.identity !== 1
        && this.user.userQualificationView.identity !== 2 && this.user.userQualificationView.identity !== 3
    if (!this.needUploadIdentity) {
      let identityUrlList = await userService.getIdentity();
      this.identityList[0].url = identityUrlList.frontImage;
      this.identityList[1].url = identityUrlList.backImage;
      this.identityList[2].url = identityUrlList.handHoldImage;
    }
    this.marriageList = await store.getConfig('marriage')
  },
  methods: {
    uploadImg(data, item) {
      item.url = URL.createObjectURL(data.file);
      item.file = data.file;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t('img_size_tips'));
      }
      return isLt2M;
    },
    selectMarriage(value) {
      this.modifiedUser.marriage = value
      this.showMarriageSelect = false;
    },
    confirmBirthday(value) {
      this.modifiedUser.birthday = formatDate(value)
      this.showBirthdaySelect = false;
    },
    async goNext() {
      if (this.needUploadIdentity) {
        let formData = new FormData();
        for (let item of this.identityList) {
          formData.append(item.name, item.file);
        }
        userService.uploadIdentity(formData)
            .then(() => this.updateUser())
            .catch(() => this.loading = false);
      } else {
        this.updateUser();
      }
    },
    updateUser() {
      let params = {
        realName: this.modifiedUser.realName,
        identity: this.modifiedUser.identity,
        sex: this.modifiedUser.sex,
        birthday: this.modifiedUser.birthday,
        marriage: this.modifiedUser.marriage,
      }
      userService.updateUser(params)
          .then(() => {
            this.loading = false
            store.updateModifiedUser(this.modifiedUser)
            this.$router.push("/baseInfo")
          }).catch(() => this.loading = false);

    }
  }
}
</script>
<style lang="less" scoped>
.id-card {
  padding-top: 44px;
  background-color: @base-color;
  min-height: calc(100% - 44px);
  display: flex;
  flex-direction: column;

  .title {
    padding: 5% 10%;
    display: flex;
    flex-direction: row;

    img {
      width: 25%;
    }

    .title-desc {
      margin-left: 8%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;

      span {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
      }
    }
  }

  .page {
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    padding: 15px 15px calc(15% + 30px);

    .img-list {
      border-bottom: 1px solid #CBC2C2
    }
  }

  .readonly {

    /deep/ input.el-input__inner {
      border-color: #E4E7ED !important;
    }

    span.item-value {
      border-color: #E4E7ED !important;
      background-color: #F5F7FA !important;
      color: #C0C4CC !important;
    }

    i {
      display: none;
    }
  }

  .identity-img-upload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 10px 30px;

    .desc {
      flex: 1;
      display: flex;
      flex-direction: column;

      .index {
        color: @base-color;
        font-size: 28px;
        font-weight: 700;
      }

      .img-desc {
        margin-top: 5px;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .uploader {
      flex: 1;
      width: 180px;
      height: 118px;
      display: flex;
      justify-content: center;

      .identity-img {
        max-width: 180px;
        max-height: 118px;
      }
    }
  }

  .user-info {
    margin: 10px;

    .info-item {
      margin: 20px 0;

      .item-desc {
        margin: 10px 0;
        display: block;
      }

      .item-value {
        display: block;
        height: 45px;
        line-height: 45px;

        /deep/ input.el-input__inner {
          border: 1px solid #7972E6;
          border-radius: 8px;
          height: 45px;
          line-height: 45px;
        }
      }

      span.item-value {
        padding-left: 15px;
        border-radius: 8px;
        border: 1px solid #7972E6;
        font-size: 14px;
      }
    }

    .select-div {
      position: relative;
      display: flex;
      align-items: center;

      span {
        width: 100%;
      }

      i {
        position: absolute;
        color: #7972E6;
        right: 10px;
        font-size: 18px;
      }
    }

    .radio-div {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .item-desc {
        flex-grow: 1;
      }

      .item-value {
        justify-content: space-between;
        flex-grow: 1;
      }

      .van-radio {
        margin-right: 0;
      }
    }
  }

  .submit-btn {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 40px);
    background-color: @button-color;
    border-color: @button-color;
    height: 45px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
  }
}
</style>