<template>
  <div class="page">
    <header-bar :title="$t('transaction_history')"/>
    <div class="order-item" v-if="order && order.status == 3">
      <div class="order-detail">
        <span class="desc">{{ $t('common_order_id') }}:</span>
        <span class="vale">{{ order.orderId }}</span>
      </div>
      <div class="order-detail">
        <span class="desc">{{ $t('common_apply_datetime') }}:</span>
        <span class="vale">{{ order.applyTime }}</span>
      </div>
      <div class="order-detail">
        <span class="desc">{{ $t('withdraw_amount') }}:</span>
        <span class="vale">{{ order.lastWithdrawAmount }}</span>
      </div>
      <div class="order-detail">
        <span class="desc">{{ $t('status') }}:</span>
        <span class="vale" :class="[statusDesc ? 'red': 'green']">{{ statusDesc || 'Pending' }}</span>
      </div>
    </div>
    <van-empty description="No data" v-else/>
  </div>
</template>
<script>

import {orderService} from "@/services";

export default {
  name: "TransactionHistory",
  data() {
    return {
      order: {},
      statusDesc: null,
    }
  },
  activated() {
    this.getOrderList();
    this.getLastWithdrawError();
  },
  methods: {
    async getOrderList() {
      let orderList = await orderService.getOrderList();
      this.order = orderList && orderList.length && orderList[0]
    },
    async getLastWithdrawError() {
      this.statusDesc = await orderService.getLastWithdrawError();
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 44px);
  padding-top: 44px;

  .order-item {
    width: calc(100% - 60px);
    margin: 20px 10px;
    background-color: #ffffff;
    padding: 10px 15px;

    .order-detail {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .desc {
        color: #999999;
      }

      .value {
        color: #333333;
      }

      .red {
        color: #F56C6C !important;
      }
    }
  }

  .green {
    color: green !important;
  }
}
</style>
