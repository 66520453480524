<template>
  <div class="bottom">
    <div class="bottom-item" v-for="item of menuList" :class="{'active': currentName===item.name}"
         @click="goto(item)" :key="item.name">
      <div class="inner-div">
        <img :src="currentName===item.name? item.activeIcon : item.icon" class="item-icon">
      <span class="item-name">{{ $t(item.name.toLowerCase()) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import home from '@/assets/home.png';
import homeGray from '@/assets/home-gray.png';
import my from '@/assets/my.png';
import myGray from '@/assets/my-gray.png';

export default {
  name: "BottomBar",
  props: ["currentName"],
  data() {
    return {
      menuList: [{
        name: 'Home',
        icon: homeGray,
        activeIcon: home,
        path: '/'
      }, {
        name: 'My',
        icon: myGray,
        activeIcon: my,
        path: '/my'
      }]
    }
  },
  methods: {
    goto(item) {
      if (item.name === this.currentName){
        return;
      }
      this.$router.push(item.path);
    }
  }
}
</script>
<style lang="less" scoped>
.bottom {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 60px;
  .bottom-item {
    flex: 1;
    justify-content: space-around;
    display: flex;

    &.active {
      .inner-div {
        background-color: #5343F4;
        border-radius: 30px;
        padding: 0 15px;
        .item-name {
          color: #ffffff;
          opacity: 1;
          display: block;
          margin-left: 5px;
        }
      }
    }
  }
  .inner-div{
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #535D66;
    .item-icon {
      height: 22px;
    }
    .item-name {
      font-size: 12px;
      color: #ffffff;
      opacity: 0.6;
      display: none;
    }
  }
}
</style>