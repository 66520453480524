<template>
  <div class="app-list">
    <header-bar :title="$t('product')" font-weight="600" :custom-back="goHome"/>
    <div class="app-item" v-for="(item, index) of appList" :key="item.id">
      <div class="app-header">
        <div class="name-div">
          <img class="logo" :src="item.logo"/>
          <span class="app-name">
              {{ item.name }}
            </span>
        </div>
        <div class="apply-div">
          <button class="apply-btn" :class="{'disabled': index >0 || item.disabled}"
                  @click="checkUser(item.id)" :disabled="index>0 || item.disabled">
            {{ unAuth ? $t('complete_msg') : $t('common_apply') }}
          </button>
        </div>
      </div>
      <div class="app-detail">
        <div class="detail-item" style="flex: 1">
          <div class="detail-desc max">
            <span class="tips">Max</span>
            <span class="max-amount">{{ item.maxAmount }}</span>
          </div>
        </div>
        <div class="detail-item second" style="flex: 2">
          <div class="detail-desc">
            <span class="desc">{{ rateFormatter(item.defaultRate) }}</span>
            <span class="tips">{{ $t('interest_rate') }}</span>
          </div>
          <div class="detail-desc">
            <span class="desc">5.0</span>
            <span class="tips">{{ $t('score') }}</span>
          </div>
        </div>
      </div>
    </div>
    <signature-dialog :next="apply" :visible="showSign"/>
  </div>
</template>
<script>
import {rateFormatter} from "@/common/formatter"
import store from "@/store";

export default {
  name: "AppList",
  data() {
    return {
      rateFormatter,
      appList: [],
      user: {userQualificationView: {}},
      showSign: false,
      currentAppId: null
    }
  },
  computed: {
    unAuth() {
      return this.user.userQualificationView.bankCard != 1 && this.user.userQualificationView.bankCard != 2
          || this.user.userQualificationView.basicInformation != 1 && this.user.userQualificationView.basicInformation != 2
          || (this.user.userQualificationView.identity != 1 && this.user.userQualificationView.identity != 2);
    }
  },
  activated() {
    this.getUser()
    this.getAppList();
  },
  methods: {
    async getUser() {
      this.user = await store.getUser(true)
    },
    async getAppList() {
      this.appList = await store.getAppList();
    },
    checkUser(appId) {
      this.currentAppId = appId;
      if (!this.user.signatureImg) {
        this.showSign = true;
      } else {
        this.apply();
      }
    },
    apply() {
      if (this.unAuth) {
        this.$router.push("/identity");
      } else {
        this.$router.push("/apply/" + this.currentAppId);
      }
    },
    goHome() {
      this.$router.push("/")
    }
  }
}
</script>
<style scoped lang="less">
.app-list {
  padding-top: 44px;
  padding-bottom: 51px;
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 95px);

  .app-item {
    background-color: #FFFFFF;
    margin: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .border {
      border: 1px dashed #CCCCCC;
      height: 100%;
    }

    .app-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .name-div {
        flex: 3;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70px;
      }

      .logo {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }

      .app-name {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-left: 10px;
      }
    }

    .apply-div {
      flex: 1;
      display: flex;
      align-items: center;
      max-width: 31%;

      .apply-btn {
        border: none;
        color: #1D62CA;
        font-size: 16px;
        padding: 8px 13px;
        border-radius: 23px;
        background: none;

        &.disabled {
          color: gray;
        }
      }
    }

    .app-detail {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
    }
    .detail-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #FFF6F6;
      padding: 8px;
      border-radius: 10px;
      margin-right: 8px;

      &.second {
        margin-right: 0;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #EDEFF7;
      }
    }
    .detail-desc {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      flex: 1;
      font-size: 18px;

      &.max {
        color: #B83232;
        font-size: 22px;
        font-weight: 700;
        .tips {
          color: #B83232;
          margin-top: 0;
          margin-bottom: 6px;
        }
      }
      .desc {
        font-weight: 600;
      }
      .tips {
        margin-top: 6px;
        color: #83848B;
      }
    }
  }
}
</style>