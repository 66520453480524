import Vue from 'vue';
import {
  Avatar,
  Container,
  Aside,
  Header,
  Main,
  Button,
  Loading,
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Switch,
  Menu,
  MenuItem,
  Submenu,
  Tag,
  Dialog,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  Alert,
  MessageBox,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  DatePicker,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Link,
  Message,
  ButtonGroup,
  Divider,
  Card,
  RadioButton,
  Tooltip,
  Upload,
  Cascader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  TimeSelect,
  MenuItemGroup,
} from 'element-ui';

Vue.use(Container);
Vue.use(Aside);
Vue.use(Header);
Vue.use(Main);
Vue.use(Button);
Vue.use(Loading.directive);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Switch);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Alert);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Popover);
Vue.use(DatePicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Link);
Vue.use(ButtonGroup);
Vue.use(Divider);
Vue.use(Card);
Vue.use(RadioButton);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Cascader);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(TimeSelect);
Vue.use(MenuItemGroup);
Vue.use(Avatar);
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
