<template>
  <div class="apply-success-div">
    <header-bar color="#ffffff"/>
    <div class="success-div">
      <div class="success-img-container">
        <img :src="successImg"/>
      </div>
      <span>{{ $t('success') }}</span>
    </div>
    <div class="btn-div">
      <van-button type="info" block @click="store.contactUs" v-if="store.showContact">
        {{ $t('click_to_contact_service') }}
      </van-button>
      <van-button type="info" block @click="backHome" v-else>
        {{ $t('back_home') }}
      </van-button>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {orderService} from "@/services";
import {rateFormatter} from "@/common/formatter"
import successImg from '@/assets/success.png';

export default {
  name: "AppLySuccess",
  data() {
    return {
      successImg,
      store,
      rateFormatter,
      loading: false,
      interest: 0,
      shouldRepay: 0,
      shouldRepayPerMonth: 0,
      orderInfo: {},
    }
  },
  async mounted() {
  },
  methods: {
    async getOrderInfo() {
      this.orderInfo = await orderService.getOrderInfo({loanOrderId: this.$route.params.orderId})
    },
    calcAmount() {
      this.interest = (this.orderInfo.rate * this.orderInfo.periodDays / 100000 * this.orderInfo.principal).toFixed(1) * 100 / 100;
      this.shouldRepay = this.interest + this.orderInfo.principal;
      this.shouldRepayPerMonth = (this.shouldRepay / this.orderInfo.periodDays).toFixed(1) * 100 / 100;
    },
    async backHome() {
      this.$router.push("/");
    },
  }
}
</script>
<style lang="less" scoped>
.apply-success-div {
  padding-top: 44px;
  min-height: calc(100% - 44px);
  color: #ffffff;
  background: @base-color;

  .success-div {
    background: @base-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 10px 10px;
    padding-bottom: 60px;

    .success-img-container {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        margin-top: 70px;
        display: block;
        width: 60%;
      }
    }

    span {
      color: #ffffff;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .split-line {
    height: 1px;
    width: calc(100% - 40px);
    background: #FFFFFF;
    margin: 30px 20px;
  }

  .order-detail {
    padding: 0 15px 30px;
    margin: -30px 20px 0;
    width: calc(100% - 70px);
    background: #FAFAFA;

    .title {
      font-size: 16px;
      text-align: center;
      margin-bottom: 15px;
      padding-top: 20px;
      padding-bottom: 15px;
      color: #000000;
      border-bottom: 1px dashed #0000009E;
    }

    .order-detail-item {
      display: flex;
      flex-direction: row;
      padding: 15px;
      border-bottom: 1px dashed #0000009E;

      .value {
        font-size: 16px;
        text-align: right;
        flex: 1;
        color: #100D40;
        font-weight: 700;
      }

      .desc {
        flex: 2;
        font-size: 14px;
        color: #999999;
      }
    }
  }


  .btn-div {
    display: flex;
    flex-direction: row;
    margin: 20px 25px 20px;
    padding-bottom: 20px;

    .van-button {
      flex: 1;
      height: 45px;
      font-size: 16px;
      background: @button-color;
      border: none;
      border-radius: 8px;
      margin: 0 25px;
      color: #333333;
      font-weight: 700;
    }
  }
}
</style>