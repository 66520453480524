<template>
  <div class="contact-service-div"
       :style="[{bottom: bottom}, {position: position}, {right: right}, {top: top}, {left: left} ]"
       v-if="store.showContact">
    <div class="content-div">
      <img :src="serviceImg">
      <div class="contact-div">
        <a @click="store.contactUs">{{ $t('click_to_contact_service') }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import serviceImg from '@/assets/service.png';
import store from "@/store";

export default {
  name: "ContactCustomService",
  props: ['bottom', 'position', 'right', 'top', 'left'],
  data() {
    return {
      store,
      serviceImg,
    }
  },
}
</script>
<style lang="less" scoped>
.contact-service-div {
  text-align: center;
  font-size: 14px;
  position: fixed;
  z-index: 99999;

  .content-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-div {
    padding: 5px 10px 5px 15px;
    margin-left: -12px;
    background: #FF9494;
    color: #ffffff;
    border-radius: 5px;
  }

  img {
    width: 35px;
    z-index: 99999;
  }

  a {
  }
}
</style>