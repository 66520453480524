import axios from 'axios';
import { Message } from 'element-ui';
import { authService } from '@/services';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

axios.defaults.baseURL = '/jackey';

export function showErrorMessage(message, code) {
  const i18nKey = `service_error_${code}`;
  if (i18n.te(i18nKey)) {
    Message.error({
      showClose: true,
      message: i18n.t(i18nKey),
    });
  } else {
    Message.error({
      showClose: true,
      message: message,
    });
  }
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    const unLockToken = sessionStorage.getItem('unlockToken');
    if (unLockToken) {
      config.headers['security-token'] = unLockToken;
    }
    store.addLoadingRef();
    return config;
  },
  (error) => {
    store.minusLoadingRef();
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    store.minusLoadingRef();
    if (process.env.NODE_ENV !== 'production') {
      console.log(response);
    }
    const { data } = response;
    const { responseType } = response.config;
    if (responseType === 'blob') {
      let fileReader = new FileReader();
      fileReader.onload = function() {
        try {
          let jsonData = JSON.parse(this.result);
          if (jsonData.code === 70005) {
            showErrorMessage(jsonData.msg);
            return;
          }
          if (jsonData.code === 20001) {
            showErrorMessage(jsonData.msg);
            authService.logout();
            router.push({ name: 'login' });
          }
          if (jsonData.code === 20003) {
            router.push({ name: 'lock' });
          }
          return Promise.reject(data);
        } catch (err) {
          return data;
        }
      };
      fileReader.readAsText(data);
      return data;
    } else {
      if (data.code !== 0) {
        showErrorMessage(data.msg, data.code);
        if (data.code === 401 || data.code === 20001 || data.code === 7) {
          authService.logout();
          router.push({ name: 'login' });
        }
        if (data.code === 20003) {
          router.push({ name: 'lock' });
        }
        return Promise.reject(data);
      }
    }
    return data.data;
  },
  (error) => {
    store.minusLoadingRef();
    if (process.env.NODE_ENV !== 'production') {
      console.log(error);
    }
    if (error.response) {
      showErrorMessage(`${i18n.t('common_network_error')} ${error.response.status}`);
    } else if (error.request) {
      if (error.message === 'Network Error') {
        showErrorMessage(i18n.t('common_network_error'));
        return false;
      }
      showErrorMessage(error.message);
    } else {
      showErrorMessage(error.message);
    }
    return Promise.reject(error);
  },
);

export default axios;
