import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/views/Login';
import Home from '@/views/Home';
import Identity from '@/views/auth/Identity';
import BaseInfo from '@/views/auth/BaseInfo';
import BankInfo from '@/views/auth/BankInfo';
import AppList from '@/views/AppList';
import Apply from '@/views/order/Apply';
import OrderList from '@/views/order/OrderList';
import OrderInfo from '@/views/order/OrderInfo';
import My from '@/views/my/My';
import BaseInfoDetail from '@/views/my/BaseInfoDetail';
import BankInfoDetail from '@/views/my/BankInfoDetail';
import Lock from '@/views/Lock';
import InformationQuestion from '@/views/auth/InformationQuestion';
import Partner from '@/views/Partner';
import TransactionHistory from '@/views/order/TransactionHistory';
import Contract from '@/views/Contract';
import RepayDetail from '@/views/order/RepayDetail';
import ApplySuccess from '@/views/order/ApplySuccess';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/register',
            name: 'register',
            component: Login,
        },
        {
            path: '/identity',
            name: 'identity',
            component: Identity,
        },
        {
            path: '/baseInfo',
            name: 'baseInfo',
            component: BaseInfo,
        },
        {
            path: '/bankInfo',
            name: 'bankInfo',
            component: BankInfo,
        },
        {
            path: '/loan',
            name: 'loan',
            component: AppList,
        },
        {
            path: '/apply/:appId',
            name: 'apply',
            component: Apply,
        },
        {
            path: '/order',
            name: 'order',
            component: OrderList,
        },
        {
            path: '/detail/:orderId',
            name: 'orderInfo',
            component: OrderInfo,
        },
        {
            path: '/my',
            name: 'my',
            component: My,
        },
        {
            path: '/baseInfoDetail',
            name: 'baseInfoDetail',
            component: BaseInfoDetail,
        },
        {
            path: '/bankInfoDetail',
            name: 'bankInfoDetail',
            component: BankInfoDetail,
        },
        {
            path: '/lock',
            name: 'lock',
            component: Lock,
        },
        {
            path: '/question',
            name: 'question',
            component: InformationQuestion,
        },
        {
            path: '/partner',
            name: 'partner',
            component: Partner,
        },
        {
            path: '/transactionHistory',
            name: 'transactionHistory',
            component: TransactionHistory,
        },
        {
            path: '/contract',
            name: 'contract',
            component: Contract,
        },
        {
            path: '/repayDetail',
            name: 'repayDetail',
            component: RepayDetail,
        },
        {
            path: '*',
            redirect: '/',
            hidden: true
        },
        {
            path: '/success/:orderId',
            name: 'applySuccess',
            component: ApplySuccess,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.query.code) {
        localStorage.setItem("inviteCode", to.query.code)
    } else if (from.query.code) {
        localStorage.setItem("inviteCode", from.query.code)
    }
    if (to.query.channel) {
        localStorage.setItem("channel", to.query.channel)
    } else if (from.query.channel) {
        localStorage.setItem("channel", from.query.channel)
    }
    next()
});

export default router;
