import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUsers,
  faCog,
  faUserCircle,
  faMobileAlt,
  faSearch,
  faPhoneVolume,
  faDollarSign,
  faExclamationTriangle,
  faClipboardList,
  faChartBar,
  faUsersCog,
  faPray,
  faUserShield,
  faExchangeAlt,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faUsers,
  faCog,
  faUserCircle,
  faMobileAlt,
  faSearch,
  faPhoneVolume,
  faDollarSign,
  faExclamationTriangle,
  faClipboardList,
  faChartBar,
  faUsersCog,
  faPray,
  faUserShield,
  faExchangeAlt,
  faSearchPlus,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
