<template>
  <div class="order-list">
    <header-bar :title="$t('order')" :hideBack="true" font-weight="600"/>
    <div class="order-item" v-for="item of orderList" :key="item.orderId" @click="view(item.orderId)">
      <div class="app-header">
        <div class="name-div">
          <img class="logo" :src="apps[item.appId].logo"/>
          <span class="app-name">
            {{ apps[item.appId].name }}
          </span>
        </div>
        <span class="status">
          {{ $t(mapOrderStatusDesc(item.status)) }}
        </span>
      </div>
      <div class="order-detail">
        <div class="detail-desc">
          <span class="amount">{{ item.principal }}</span>
          <span class="time">{{ item.applyTime }}</span>
        </div>
        <div class="detail-desc">
          <button class="review">{{ $t('common_check') }}</button>
        </div>
      </div>
    </div>
    <bottom-bar current-name="Order"></bottom-bar>
    <van-icon name="service" size="50px" class="service" @click="store.contactUs" v-if="store.showContact"/>
  </div>
</template>
<script>
import {mapOrderStatusDesc} from "@/common/formatter"
import store from "@/store";
import {orderService} from "@/services";

export default {
  name: "OrderList",
  data() {
    return {
      store,
      mapOrderStatusDesc,
      orderList: [],
      apps: {},
    }
  },
  activated() {
    this.getAppList();
    this.getOrderList();
  },
  methods: {
    async getAppList() {
      let appList = await store.getAppList();
      appList.forEach(r => this.apps[r.id] = r);
    },
    async getOrderList() {
      this.orderList = await orderService.getOrderList();
    },
    view(orderId) {
      this.$router.push("/detail/" + orderId);
    },
  }
}
</script>
<style scoped lang="less">
.order-list {
  padding-top: 44px;
  padding-bottom: 51px;
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 95px);

  .order-item {
    background-color: #FFFFFF;
    margin: 15px;
    padding: 10px;

    .app-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .name-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        width: 100px;
      }

      .logo {
        width: 30px;
        height: 30px;
      }

      .app-name {
        margin-left: 10px;
        font-weight: 700;
        font-size: 18px;
      }

      .status {
        border: none;
        color: rgb(41, 105, 255);
        text-align: center;
        font-size: 16px;
        line-height: 30px;
      }
    }

    .order-detail {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      align-items: center;

      .amount {
        color: rgb(250, 173, 20);
        font-size: 20px;
        font-weight: 700;
      }
    }

    .detail-desc {
      display: flex;
      flex-direction: column;

      .time {
        margin-top: 5px;
        font-size: 14px;
        color: #666666;
      }
    }

    .review {
      border: none;
      background-color: rgb(41, 105, 255);
      color: #FFFFFF;
      font-size: 14px;
      padding: 5px 10px;

    }

    .tips {
      padding-top: 5px;
      color: #999999;
      font-size: 14px;
    }
  }

  .service {
    position: fixed;
    right: 30px;
    bottom: 80px;
  }
}
</style>