<template>
  <div class="base-info">
    <header-bar :title="$t('base_info_title')"/>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_name') }}</span>
        <span class="item-value">
          {{ user.realName }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_id_number') }}</span>
        <span class="item-value">{{ identityMasked ? displayIdentity : user.identity }}
          <img class="eye" :src='identityMasked? openEyeIcon : closeEyeIcon ' @click="identityMasked=!identityMasked">
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_gender') }}</span>
        <span class="item-value">
          {{ user.sex === 1 ? $t('base_info_gender_male') : $t('base_info_gender_female') }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_birthday') }}</span>
        <span class="item-value">
          {{ user.birthday }}
        </span>
      </div>
    </div>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">{{ $t('common_bank') }}</span>
        <span class="item-value">
          {{ user.bankName }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('common_bank_account') }}</span>
        <span class="item-value">{{ cardNoMasked ? displayCardNo : user.cardNo }}
          <img class="eye" :src='cardNoMasked? openEyeIcon : closeEyeIcon ' @click="cardNoMasked=!cardNoMasked">
        </span>
      </div>
    </div>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_marriage') }}</span>
        <span class="item-value">
          {{ user.marriage }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_education') }}</span>
        <span class="item-value">
          {{ user.education }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_job_info') }}</span>
        <span class="item-value">{{ user.job }}</span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_address') }}</span>
        <span class="item-value">
          {{ user.provinceCity }}
        </span>
      </div>
      <div class="info-item">
        <span class="item-desc">{{ $t('base_info_detail_address') }}</span>
        <span class="item-value">
          {{ user.address }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { mask }from "@/common/formatter"

export default {
  name: 'BaseInfoDetail',
  data() {
    return {
      mask,
      user: {},
      displayCardNo: '',
      displayIdentity: '',
      cardNoMasked: true,
      identityMasked: true,
      closeEyeIcon: require('@/assets/eye-close.png'),
      openEyeIcon: require('@/assets/eye-open.png')
    }
  },
  async mounted() {
    this.user = await store.getUser();
    this.displayCardNo = this.mask(this.user.cardNo);
    this.displayIdentity = this.mask(this.user.identity);
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.base-info {
  padding-top: 44px;
  background-color: rgb(246, 248, 253);
  min-height: calc(100% - 44px);
}

.user-info {
  margin: 30px 10px;
  background: #FFFFFF;

  .info-item {
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px dashed silver;
    .item-desc {
      color: #999999;
    }

    .item-value {
      width: 150px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: end;
    }

    span.item-value {
      width: 150px;
      color: #606266;
      line-height: 30px;
      padding: 0 15px;
    }
  }

  .eye {
    height: 18px;
    margin-left: 5px;
  }
}
</style>